import { isNil } from 'lodash';
import { ExportResponseItem, ListResponse, SuccessResponseEntity, SuccessResponseList } from 'types/app';
import axiosServices from 'utils/axios';
import {
  CouponInvoiceListItem,
  SellerCouponInvoiceOrderFilter,
  SellerCouponInvoiceOrdersListItem,
  SellerCouponInvoicesListItem
} from '../types';
import { I18n } from 'types/config';

export const getCouponInvoiceListAPI = async ({
  i18n,
  page,
  search,
  sort,
  offset
}: {
  page: number;
  i18n: string;
  search: string;
  sort: { sort_by: string | null; sort_type: string | null };
  offset: number;
}) => {
  const searchParams = new URLSearchParams();

  searchParams.append('page', page.toString());
  searchParams.append('offset', offset.toString());
  if (sort?.sort_by) searchParams.append('sort_by', sort?.sort_by);
  if (sort?.sort_type) searchParams.append('sort_type', sort?.sort_type);
  if (search) searchParams.append('search[value]', search);

  const endpoint = `coupon-invoices?${searchParams.toString()}`;
  const res = await axiosServices.get<SuccessResponseList<ListResponse<CouponInvoiceListItem[]>>>(endpoint, {
    headers: { 'Accept-Language': i18n }
  });
  if (isNil(res.data.success) || !res.data.success) throw new Error(res.data.message);
  return res.data.items;
};

export const getSellerCouponInvoicesOrdersListAPI = async ({
  id,
  i18n,
  page,
  search,
  params,
  isHistory,
  sort,
  offset
}: {
  id: string;
  page: number;
  i18n: string;
  search: string;
  params: SellerCouponInvoiceOrderFilter;
  isHistory?: boolean;
  sort: { sort_by: string | null; sort_type: string | null };
  offset: number;
}) => {
  const searchParams = new URLSearchParams();

  searchParams.append('page', page.toString());
  searchParams.append('offset', offset.toString());
  if (params.customer_id) searchParams.append('customer_id', params.customer_id);
  if (params.invoice_id) searchParams.append('invoice_id', params.invoice_id);
  if (params.date_start) searchParams.append('date_start', params.date_start);
  if (params.date_end) searchParams.append('date_end', params.date_end);
  if (sort?.sort_by) searchParams.append('sort_by', sort?.sort_by);
  if (sort?.sort_type) searchParams.append('sort_type', sort?.sort_type);
  if (search) searchParams.append('search[value]', search);

  const endpoint = `coupon-invoices/${id}/list-orders?${searchParams.toString()}`;
  const res = await axiosServices.get<SuccessResponseList<ListResponse<SellerCouponInvoiceOrdersListItem[]>>>(endpoint, {
    headers: { 'Accept-Language': i18n }
  });
  if (isNil(res.data.success) || !res.data.success) throw new Error(res.data.message);
  return res.data.items;
};

export const getCouponInvoiceRemainingAPI = async ({ i18n, id }: { i18n: string; id: string }) => {
  const endpoint = `/coupon-invoices/${id}/remaining-amount`;
  const res = await axiosServices.get<SuccessResponseEntity<{ total: number }>>(endpoint, {
    headers: { 'Accept-Language': i18n }
  });
  if (isNil(res.data.success) || !res.data.success) throw new Error(res.data.message);
  return res.data.item;
};

export const exportSellerCouponInvoicesOrdersListAPI = async ({
  id,
  i18n,
  search,
  params,
  isFiltered,
  isInvoicesList = false
}: {
  id: string;
  i18n: string;
  search: string;
  params: SellerCouponInvoiceOrderFilter;
  isFiltered: boolean;
  isInvoicesList?: boolean;
}) => {
  const searchParams = new URLSearchParams();

  if (params.invoice_id) searchParams.append('invoice_id', params.invoice_id);
  if (isFiltered) {
    if (params.customer_id) searchParams.append('customer_id', params.customer_id);
    if (params.date_start) searchParams.append('date_start', params.date_start);
    if (params.date_end) searchParams.append('date_end', params.date_end);
  }
  if (search) searchParams.append('search[value]', search);

  let endpoint = `coupon-invoices/${id}/data/order/export?${searchParams.toString()}`;
  if (isInvoicesList) endpoint = `coupon-invoices/${id}/data/export?${searchParams.toString()}`;

  const res = await axiosServices.get<SuccessResponseList<ExportResponseItem>>(endpoint, {
    headers: { 'Accept-Language': i18n }
  });
  if (isNil(res.data.success) || !res.data.success) throw new Error(res.data.message);
  return { ...res.data.items, endpoint };
};

export const getSellerCouponInvoicesListAPI = async ({
  id,
  i18n,
  page,
  search,
  params,
  sort,
  offset
}: {
  id: string;
  page: number;
  i18n: string;
  search: string;
  params: Omit<SellerCouponInvoiceOrderFilter, 'customer_id'>;
  sort: { sort_by: string | null; sort_type: string | null };
  offset: number;
}) => {
  const searchParams = new URLSearchParams();

  searchParams.append('page', page.toString());
  searchParams.append('offset', offset.toString());
  if (params.date_start) searchParams.append('date_start', params.date_start);
  if (params.date_end) searchParams.append('date_end', params.date_end);
  if (sort?.sort_by) searchParams.append('sort_by', sort?.sort_by);
  if (sort?.sort_type) searchParams.append('sort_type', sort?.sort_type);
  if (search) searchParams.append('search[value]', search);

  const endpoint = `coupon-invoices/${id}/list-invoices?${searchParams.toString()}`;
  const res = await axiosServices.get<SuccessResponseList<ListResponse<SellerCouponInvoicesListItem[]>>>(endpoint, {
    headers: { 'Accept-Language': i18n }
  });
  if (isNil(res.data.success) || !res.data.success) throw new Error(res.data.message);
  return res.data.items;
};

export const uploadSellerCouponInvoiceAPI = async ({
  formData,
  i18n,
  invoiceId
}: {
  formData: FormData;
  i18n: string;
  invoiceId?: string;
}) => {
  const endpoint = `coupon-invoices${invoiceId ? `/${invoiceId}` : ''}`;
  const res = await axiosServices.post<SuccessResponseEntity<null>>(endpoint, formData, {
    headers: { 'Accept-Language': i18n, Media: '1' }
  });

  if (isNil(res.data.success) || !res.data.success) throw new Error(res.data.message);
  return res.data;
};

export const updateCouponInvoiceStatusAPI = async ({ id, formData, i18n }: { id: string; formData: FormData; i18n: string }) => {
  const endpoint = `/coupon-invoices/change-status/${id}`;
  const res = await axiosServices.post<SuccessResponseEntity<null>>(endpoint, formData, {
    headers: { 'Accept-Language': i18n }
  });

  if (isNil(res.data.success) || !res.data.success) throw new Error(res.data.message);
  return res.data;
};

export const deleteCouponInvoiceAPI = async ({ id, i18n }: { id: string; i18n: I18n }) => {
  const endpoint = `/coupon-invoices/${id}`;
  const res = await axiosServices.delete<SuccessResponseEntity<null>>(endpoint, {
    headers: { 'Accept-Language': i18n }
  });

  if (isNil(res.data.success) || !res.data.success) throw new Error(res.data.message);
  return res.data;
};

export async function getInvoicesCount(i18n: string) {
  const res = await axiosServices.get<SuccessResponseEntity<{ count: number }>>('/invoices/list/count', {
    headers: { 'Accept-Language': i18n }
  });
  if (!!!res.data.success) throw new Error(res.data.message);
  return res.data.item;
}
