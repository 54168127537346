import { ReactNode } from 'react';

// third-party

// project import
import { I18nextProvider } from 'react-i18next';
import i18nInit from '../i18n';

// ==============================|| LOCALIZATION ||============================== //

interface Props {
  children: ReactNode;
}

const Locales = ({ children }: Props) => {
  return <I18nextProvider i18n={i18nInit}>{children}</I18nextProvider>;
};

export default Locales;
