const Question = ({ color = '#2D9B15' }: any) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3008 3.59639C14.8677 4.1209 15.3119 4.73468 15.6244 5.41764C15.9502 6.12962 16.1154 6.88847 16.1154 7.66964C16.1154 8.33475 15.9949 8.9887 15.756 9.61141C15.5261 10.2118 15.1936 10.7653 14.7673 11.263C13.9214 12.2451 12.7385 12.9481 11.435 13.2428C11.0977 13.3187 10.8542 13.627 10.8542 13.9777V14.6786C10.8542 14.8167 10.7429 14.928 10.6048 14.928H9.39941C9.26133 14.928 9.14997 14.8167 9.14997 14.6786V13.9777C9.15076 13.422 9.33864 12.8827 9.68333 12.4468C10.0292 12.0094 10.518 11.7036 11.0603 11.5809C12.009 11.3666 12.8663 10.8598 13.4735 10.1521C14.0874 9.43777 14.4111 8.5805 14.4111 7.66964C14.4111 5.44831 12.4332 3.6423 9.99986 3.6423C7.56657 3.6423 5.58859 5.44831 5.58859 7.66964V8.27232C5.58859 8.41041 5.47723 8.52176 5.33915 8.52176H4.13379C3.9957 8.52176 3.88435 8.41041 3.88435 8.27232V7.66964C3.88435 6.88847 4.04951 6.13186 4.37537 5.41764C4.68784 4.73244 5.13199 4.1209 5.6989 3.59416C6.85058 2.52729 8.37946 1.93806 9.99986 1.93806C11.6203 1.93806 13.1491 2.52729 14.3008 3.59639ZM8.75042 17.9643C8.75042 17.2749 9.31044 16.7148 9.99986 16.7148C10.6893 16.7148 11.2493 17.2749 11.2493 17.9643C11.2493 18.6537 10.6893 19.2137 9.99986 19.2137C9.31044 19.2137 8.75042 18.6537 8.75042 17.9643Z"
        fill={color}
        stroke={color}
        stroke-width="0.00111607"
      />
    </svg>
  );
};
export default Question;
