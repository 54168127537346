const OrderPayment = ({ color = '#2D9B15' }: any) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_8670_244473)">
        <path
          d="M2.96793 19.5687L2.9318 19.5326L2.89647 19.5695L2.14686 20.3523C1.81371 20.7002 1.22383 20.4615 1.22383 19.9816V3.50719C1.22383 1.91972 2.51539 0.628125 4.10285 0.628125L9.98775 0.628145L15.8744 0.628164C17.4812 0.628164 18.7761 1.9348 18.7761 3.52832V8.24387C18.7761 8.53985 18.5361 8.7798 18.2402 8.7798H14.0976H14.0476V8.8298V10.0619V10.1068L14.0922 10.1116C16.7089 10.3931 18.7532 12.6146 18.7532 15.3047C18.7532 18.1848 16.4099 20.5281 13.5297 20.5281C12.2975 20.5281 11.1638 20.099 10.2696 19.3826L10.2348 19.3547L10.2032 19.3861L9.21632 20.3626C9.00736 20.5693 8.67083 20.5693 8.46208 20.3623L8.42688 20.3978L8.46207 20.3623L7.69176 19.5988L7.65656 19.5639L7.62136 19.5988L6.85136 20.3622C6.64242 20.5693 6.30552 20.5693 6.09661 20.3622L5.33857 19.6104L5.30322 19.5753L5.26801 19.6105L4.51766 20.3607C4.30836 20.5698 3.96907 20.5698 3.75977 20.3606L2.96793 19.5687ZM17.6542 7.70793H17.7042V7.65793V3.52832C17.7042 2.52012 16.8841 1.7 15.8759 1.7C14.8677 1.7 14.0476 2.52012 14.0476 3.52832V7.65793V7.70793H14.0976H17.6542ZM8.80449 19.1925L8.83966 19.2274L8.87486 19.1925L9.44521 18.6282L9.47719 18.5966L9.44909 18.5614C6.89933 15.3732 8.85818 10.5824 12.9314 10.1157L12.9757 10.1107V10.0661V3.52832C12.9757 2.87227 13.1949 2.2667 13.5636 1.7802L13.6244 1.7H13.5238H4.10285C3.10633 1.7 2.2957 2.51067 2.2957 3.50719V18.5225V18.647L2.38181 18.5571L2.53642 18.3957C2.73883 18.1843 3.08366 18.1685 3.3025 18.3874L3.33785 18.352L3.3025 18.3874L4.10355 19.1884L4.1389 19.2237L4.17426 19.1884L4.92289 18.44L4.88754 18.4046L4.92289 18.44C5.13154 18.2314 5.46961 18.2307 5.67917 18.4385L6.43893 19.192L6.47414 19.2269L6.50935 19.192L7.27918 18.4287C7.4881 18.2216 7.82485 18.2215 8.03378 18.4286L8.06898 18.3931L8.03379 18.4286L8.80449 19.1925ZM13.5297 19.4562C15.8189 19.4562 17.6813 17.5939 17.6813 15.3047C17.6813 13.0155 15.8189 11.1531 13.5297 11.1531C11.2405 11.1531 9.37817 13.0155 9.37817 15.3047C9.37817 17.5939 11.2405 19.4562 13.5297 19.4562Z"
          fill={color}
          stroke={color}
          stroke-width="0.1"
        />
        <path
          d="M13.1391 14.9265H13.1891V14.8765V13.6797C13.1891 13.3837 13.4291 13.1438 13.7251 13.1438C14.0211 13.1438 14.261 13.3837 14.261 13.6797V15.4624C14.261 15.7584 14.0211 15.9984 13.7251 15.9984H11.9531C11.6571 15.9984 11.4172 15.7584 11.4172 15.4624C11.4172 15.1664 11.6571 14.9265 11.9531 14.9265H13.1391Z"
          fill={color}
          stroke={color}
          stroke-width="0.1"
        />
        <path
          d="M7.2004 5.13942L7.20041 5.13942C7.24821 5.22573 7.33476 5.29093 7.43771 5.34702C7.54119 5.40341 7.66847 5.45431 7.80444 5.50824L7.81668 5.5131C8.0882 5.62078 8.39912 5.74407 8.6688 5.95424C9.21071 6.37653 9.3745 6.96344 9.26138 7.49443C9.14795 8.02686 8.75578 8.5043 8.18145 8.70326L8.14782 8.71491V8.75051V8.87008C8.14782 9.16606 7.90786 9.40602 7.61188 9.40602C7.3159 9.40602 7.07594 9.16606 7.07594 8.87008V8.79555V8.75513L7.03642 8.74666C6.72978 8.68094 6.44453 8.56301 6.22253 8.41777L6.19516 8.45961L6.22253 8.41777C5.97486 8.25574 5.9054 7.9236 6.06743 7.67589L6.02559 7.64852L6.06743 7.67588C6.22942 7.42822 6.56163 7.35879 6.80931 7.52079C6.96279 7.62119 7.16477 7.68855 7.35861 7.71658C7.55114 7.74443 7.7447 7.73476 7.87851 7.67181C8.06175 7.58561 8.20088 7.40582 8.22173 7.21906L8.22173 7.21905C8.24442 7.01549 8.11629 6.86896 7.95752 6.76221C7.80915 6.66246 7.62123 6.58853 7.46783 6.52818C7.45683 6.52386 7.44601 6.5196 7.4354 6.51541C7.02816 6.35455 6.54067 6.16048 6.26277 5.65878C5.86764 4.94537 6.21909 3.98234 7.04349 3.67342L7.07594 3.66126V3.6266V3.53906C7.07594 3.24308 7.3159 3.00313 7.61188 3.00313C7.90786 3.00313 8.14782 3.24308 8.14782 3.53906V3.58738V3.6253L8.18433 3.63553C8.42758 3.70367 8.65112 3.82004 8.82646 3.98175C9.04401 4.18241 9.05773 4.52148 8.85704 4.73907C8.65638 4.95663 8.31731 4.97038 8.09973 4.76966L8.09972 4.76965C8.02093 4.69698 7.87914 4.65264 7.74057 4.63998C7.60162 4.62728 7.44811 4.64478 7.34515 4.71402L7.34515 4.71402C7.27309 4.76248 7.22011 4.83533 7.19299 4.911C7.16616 4.98585 7.16236 5.07073 7.2004 5.13942Z"
          fill={color}
          stroke={color}
          stroke-width="0.1"
        />
        <path
          d="M4.14062 10.6281H7.07031C7.36629 10.6281 7.60625 10.8681 7.60625 11.1641C7.60625 11.46 7.36629 11.7 7.07031 11.7H4.14062C3.84465 11.7 3.60469 11.46 3.60469 11.1641C3.60469 10.8681 3.84465 10.6281 4.14062 10.6281Z"
          fill={color}
          stroke={color}
          stroke-width="0.1"
        />
        <path
          d="M4.13867 12.9797H7.07031C7.36629 12.9797 7.60625 13.2196 7.60625 13.5156C7.60625 13.8116 7.36629 14.0516 7.07031 14.0516H4.13867C3.84269 14.0516 3.60273 13.8116 3.60273 13.5156C3.60273 13.2196 3.84269 12.9797 4.13867 12.9797Z"
          fill={color}
          stroke={color}
          stroke-width="0.1"
        />
        <path
          d="M4.13672 15.3156H7.07031C7.36629 15.3156 7.60625 15.5556 7.60625 15.8516C7.60625 16.1475 7.36629 16.3875 7.07031 16.3875H4.13672C3.84074 16.3875 3.60078 16.1475 3.60078 15.8516C3.60078 15.5556 3.84074 15.3156 4.13672 15.3156Z"
          fill={color}
          stroke={color}
          stroke-width="0.1"
        />
      </g>
      <defs>
        <clipPath id="clip0_8670_244473">
          <rect width="20" height="20" fill="white" transform="translate(0 0.578125)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default OrderPayment;
