const Invoices = ({ fillColor = '#323232' }: { fillColor: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 14 14">
      <g clipPath="url(#clip0_12641_79394)">
        <path
          fill={fillColor}
          d="M3.625 3.225a.125.125 0 00-.125.125v.75c0 .068.056.125.125.125h6A.125.125 0 009.75 4.1v-.75a.125.125 0 00-.125-.125h-6zM6.5 5.475H3.625A.125.125 0 003.5 5.6v.75c0 .068.056.125.125.125H6.5a.125.125 0 00.125-.125V5.6a.125.125 0 00-.125-.125zm-.75 7.156h-3.5v-11H11v5c0 .069.056.125.125.125H12a.125.125 0 00.125-.125V1.006a.5.5 0 00-.5-.5h-10a.5.5 0 00-.5.5v12.25a.5.5 0 00.5.5H5.75a.125.125 0 00.125-.125v-.875a.125.125 0 00-.125-.125zm6.875-1.375h-2.25v-.572a1.75 1.75 0 10-1 0v.572h-2.25a.25.25 0 00-.25.25v2.375c0 .137.112.25.25.25h5.5a.25.25 0 00.25-.25v-2.375a.25.25 0 00-.25-.25zm-3.531-2.25a.782.782 0 111.563 0 .782.782 0 01-1.563 0zm2.812 4.156H7.844v-.937h4.062v.937z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_12641_79394">
          <path fill="#fff" d="M0 0H14V14H0z" transform="translate(0 .321)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Invoices;
