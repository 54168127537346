import { ExportItemProps } from 'types/export';
import axiosServices, { responseSuccess, responseError } from 'utils/axios';

export function listSellers(
  page: number,
  trashed: string,
  i18n: string,
  setSellers: (data: any) => void,
  setPagination: (data: any) => void,
  offset: number,
  search: string,
  sort: { sort_by: string | null; sort_type: string | null },
  filterParams: any,
  isExporting?: boolean,
  updateExportedList?: (item: ExportItemProps) => void
) {
  const endpoint = `sellers/${isExporting ? 'data/export' : ''}?page=${page}&trashed=${trashed}&offset=${offset}
  ${sort?.sort_by ? `&sort_by=${sort?.sort_by}&sort_type=${sort?.sort_type}` : ''}
  ${search ? `&search[value]=${search}` : ''}&custom_search[start]=${
    filterParams.date_from ? filterParams.date_from : ''
  }&custom_search[end]=${filterParams.date_to ? filterParams.date_to : ''}&custom_search[is_professional]=${
    filterParams.is_professional ? filterParams.is_professional : ''
  }&custom_search[has_product]=${filterParams.has_product ? filterParams.has_product : ''}&custom_search[status]=${
    filterParams.filter_status ? filterParams.filter_status : ''
  }`;

  return axiosServices
    .get(endpoint, {
      headers: { 'Accept-Language': i18n }
    })
    .then((res) => {
      if (!isExporting) {
        setPagination(res.data.items.meta.pagination);
        setSellers(res.data.items.data);
      } else {
        const item = {
          file: res.data.items?.name,
          uuid: res.data.items?.uuid,
          endpoint: endpoint
        };
        updateExportedList?.(item);
      }
    });
}

export function getSellerData(i18n: string, id: string | undefined, setData: (data: any) => void, setRenderFlag: (data: any) => void) {
  return axiosServices.get(`sellers/${id}`, { headers: { 'Accept-Language': i18n } }).then((res) => {
    setData(res.data.item.user);
    setRenderFlag(true);
  });
}

export function getSellerDropdown(
  i18n: string,
  list: any[],
  setList: (data: { pagination: { more: boolean }; results: any[] }) => void,
  page: number,
  search: string,
  sellerId?: string | null
) {
  return axiosServices
    .get(`/sellers/data/search?page=${page}${search.length > 0 ? '&search=' + search : ''}${sellerId ? `&id=${sellerId}` : ''}`, {
      headers: { 'Accept-Language': i18n }
    })
    .then((res) => {
      const newData = {
        pagination: res.data.items.pagination,
        results: [...list, ...res.data.items.results]
      };
      setList(newData);
    });
}

export async function getSellerAjaxDropdown({ i18n, page, search }: { i18n: string; page: number; search: string }) {
  const res = await axiosServices.get(`/sellers/data/search?page=${page}${search.length > 0 ? '&search=' + search : ''}`, {
    headers: { 'Accept-Language': i18n }
  });
  return {
    ...res.data.items
  };
}

export function sellerSecondStep(form: FormData, i18n: string) {
  return axiosServices.post(`sellers/register/second-step`, form, { headers: { 'Accept-Language': i18n, Media: '1' } }).then(
    (res) => {
      if (!res.data.success) {
        responseError();
        return res.data;
      }
      responseSuccess('seller.success.second_step', 'user_managment');
      return { success: true };
    },
    (err: any) => {
      responseError();
      return err;
    }
  );
}

export function addSeller(form: FormData, i18n: string) {
  return axiosServices.post(`sellers`, form, { headers: { 'Accept-Language': i18n, Media: '1' } }).then(
    (res) => {
      if (res.data.errors) {
        return res.data;
      }
      responseSuccess('seller.success.create', 'user_managment');
      return { success: true };
    },
    (err: any) => {
      responseError('seller.errors.create', 'user_managment');
      return err;
    }
  );
}

export function updateSeller(id: string | undefined, form: FormData, i18n: string) {
  return axiosServices.post(`sellers/${id}`, form, { headers: { 'Accept-Language': i18n, Media: '1' } }).then(
    (res) => {
      if (res.data.errors) {
        return res.data;
      }
      responseSuccess('seller.success.update', 'user_managment');
      return { success: true };
    },
    (err: any) => {
      responseError('seller.errors.update', 'user_managment');
      return err;
    }
  );
}

export function updateSellerStatus(id: number, status: string, i18n: string) {
  return axiosServices.post(`sellers/${id}/change-status`, { status }, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      responseSuccess('seller.success.update', 'user_managment');
    },
    (err: any) => {
      responseError('seller.errors.update', 'user_managment');
    }
  );
}

export function deleteSeller(id: number, i18n: string) {
  return axiosServices.delete(`users/${id}`, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      if (res.data.success) responseSuccess(res.data.message);
      else responseError(res.data.message);
    },
    (err: any) => {
      responseError(err.message);
    }
  );
}

export function restoreSeller(id: number, i18n: string) {
  return axiosServices.post(`users/${id}/restore`, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      responseSuccess('seller.success.restore', 'user_managment');
    },
    (err: any) => {
      responseError('seller.errors.restore', 'user_managment');
    }
  );
}

export function loginById(form: FormData, i18n: string) {
  return axiosServices.post(`sellers/login-by-id`, form, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      responseSuccess('seller.success.login', 'user_managment');
      return res;
    },
    (err: any) => {
      responseError('seller.errors.login', 'user_managment');
      return err;
    }
  );
}

export function sellerAddNote(id: number, note: string, i18n: string) {
  return axiosServices.post(`sellers/${id}/save-notes?notes=${note}`, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      responseSuccess('seller.success.note', 'user_managment');
    },
    (err: any) => {
      responseError('seller.errors.note', 'user_managment');
    }
  );
}

export async function getSellerAccountStatment({
  i18n,
  sellerId,
  startDate,
  endDate,
  updateExportedList
}: {
  i18n: string;
  sellerId: string;
  startDate: string | null;
  endDate: string | null;
  updateExportedList: (item: ExportItemProps) => void;
}) {
  const searchParams = new URLSearchParams();
  if (startDate) searchParams.append('date_from', startDate);
  if (endDate) searchParams.append('date_to ', endDate);
  const endpoint = `/sellers/${sellerId}/account-statement${searchParams.toString() ? '?' + searchParams.toString() : ''}`;

  const res = await axiosServices.get(endpoint, {
    headers: { 'Accept-Language': i18n }
  });
  if (res.data.success) {
    const item = {
      file: res.data.items.name,
      uuid: res.data.items.uuid,
      endpoint: endpoint
    };
    updateExportedList(item);
  } else {
    responseError();
  }
}
