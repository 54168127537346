const Delivered = ({ color = '#2D9B15' }: any) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.36352 8.67188V17.3082L3.12109 15.4901V9.70218L6.84837 10.7628L9.36352 8.67188Z"
        stroke={color}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9999 16.5182L9.36353 17.3061V8.66974L15.6059 6.85156V11.5485"
        stroke={color}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.8787 10.7607L18.1211 8.97278L15.6059 6.85156L9.36353 8.66974L11.8787 10.7607Z"
        stroke={color}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.84838 10.7607L0.605957 8.97278L3.12111 6.85156L9.36353 8.66974L6.84838 10.7607Z"
        stroke={color}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.84838 2.94531L0.605957 4.73319L3.12111 6.8544L9.36353 5.03622L6.84838 2.94531Z"
        stroke={color}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.8787 2.94531L18.1211 4.73319L15.6059 6.8544L9.36353 5.03622L11.8787 2.94531Z"
        stroke={color}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M15.6059 6.85724L9.36353 5.03906" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M3.12109 6.85724L9.36352 5.03906V8.67543"
        stroke={color}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.3323 16.2623C17.8167 17.7834 16.174 18.5704 14.6817 18.0645C13.1893 17.5586 12.3639 15.9349 12.8795 14.4139C13.3951 12.8929 15.0378 12.1058 16.5301 12.6117C17.2189 12.8452 17.9165 13.4974 17.9165 13.4974L18.385 14.337L18.4719 15.1898C18.5084 15.5541 18.449 15.9179 18.3323 16.2623Z"
        stroke={color}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.9466 13.9708L16.9824 14.0055L16.9466 13.9708L15.3786 15.5878L14.7197 14.9083L14.7197 14.9083C14.6536 14.8401 14.5637 14.8016 14.4695 14.8016C14.3754 14.8016 14.2854 14.8401 14.2194 14.9083C14.1533 14.9764 14.1165 15.0684 14.1165 15.1641C14.1165 15.2597 14.1533 15.3518 14.2194 15.4198L15.1285 16.3573C15.1612 16.3911 15.2001 16.4179 15.243 16.4363C15.286 16.4546 15.332 16.4641 15.3786 16.4641C15.4252 16.4641 15.4713 16.4546 15.5142 16.4363C15.5572 16.4179 15.5961 16.3911 15.6288 16.3573L17.447 14.4824C17.4797 14.4486 17.5055 14.4087 17.5232 14.3648C17.5408 14.3209 17.5498 14.274 17.5498 14.2266C17.5498 14.1792 17.5408 14.1322 17.5232 14.0883C17.5055 14.0445 17.4797 14.0045 17.447 13.9708C17.4143 13.9371 17.3753 13.9102 17.3324 13.8919C17.2895 13.8735 17.2434 13.8641 17.1968 13.8641C17.1502 13.8641 17.1041 13.8735 17.0612 13.8919C17.0183 13.9102 16.9793 13.9371 16.9466 13.9708Z"
        fill={color}
        stroke={color}
        stroke-width="0.1"
      />
    </svg>
  );
};
export default Delivered;
