import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const EarningsIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} style={{ ...props.style, fontSize: '1.5rem' }}>
      <svg width="20" height="20" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_6158_162830)">
          <mask id="mask0_6158_162830" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="31" height="30">
            <path d="M0.666504 1.90735e-06H30.6665V30H0.666504V1.90735e-06Z" fill="white" />
          </mask>
          <g mask="url(#mask0_6158_162830)">
            <path
              d="M18.415 22.6926C19.312 22.9916 20.2847 22.5275 20.6167 21.6422C20.9665 20.7094 20.472 19.6726 19.5268 19.3575L11.8893 16.8306C10.2626 16.2882 7.15752 16.1838 5.06104 18.2803"
              stroke-miterlimit="10"
              strokeWidth={'1.5'}
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.06104 18.2812H1.54541V27.0703H5.06104V18.2812Z"
              stroke-miterlimit="10"
              strokeWidth={'1.5'}
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.06104 25.3116L13.0297 27.1224C14.2219 27.3953 15.5009 27.5525 16.7239 27.5382C19.8883 27.5011 22.9654 26.4688 25.5405 24.6295L29.0514 22.0285C29.872 21.4425 30.0323 20.2876 29.4023 19.5002C28.8191 18.7712 27.7678 18.6253 27.0081 19.1679L23.1837 21.993C21.8108 22.9736 20.0515 23.2381 18.4509 22.7046L13.03 20.9171"
              stroke-miterlimit="10"
              strokeWidth={'1.5'}
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.4089 11.7419C22.5115 12.5689 24.6191 11.9833 24.6191 10.8096C24.6191 8.86398 20.894 10.1186 20.894 8.17293C20.894 6.99396 23.0051 6.41635 24.1042 7.2407"
              stroke-miterlimit="10"
              strokeWidth={'1.5'}
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path d="M22.7563 13.125V12.1289" stroke-miterlimit="10" strokeWidth={'1.5'} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M22.7563 6.85547V5.85938" stroke-miterlimit="10" strokeWidth={'1.5'} stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M29.7876 9.49219C29.7876 13.3754 26.6396 16.5234 22.7563 16.5234C18.8731 16.5234 15.7251 13.3754 15.7251 9.49219C15.7251 5.60894 18.8731 2.46094 22.7563 2.46094C26.6396 2.46094 29.7876 5.60894 29.7876 9.49219Z"
              stroke-miterlimit="10"
              strokeWidth={'1.5'}
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_6158_162830">
            <rect width="30" height="30" fill="white" transform="translate(0.666504)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
