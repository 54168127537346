import { CSSProperties, ReactElement, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

// project import
import MainCard from 'components/MainCard';

// assets
import { ApartmentOutlined, HomeOutlined, HomeFilled } from '@ant-design/icons';

// types
import { OverrideIcon } from 'types/root';
import { NavItemType } from 'types/menu';
import { useTranslation } from 'react-i18next';

// ==============================|| BREADCRUMBS ||============================== //

export interface BreadCrumbSxProps extends CSSProperties {
  mb?: string;
  bgcolor?: string;
}

interface Props {
  card?: boolean;
  divider?: boolean;
  icon?: boolean;
  icons?: boolean;
  maxItems?: number;
  navigation?: { items: NavItemType[] };
  rightAlign?: boolean;
  separator?: OverrideIcon;
  title?: boolean;
  titleBottom?: boolean;
  sx?: BreadCrumbSxProps;
}

const Breadcrumbs = ({
  card,
  divider = true,
  icon,
  icons,
  maxItems,
  navigation,
  rightAlign,
  separator,
  title,
  titleBottom,
  sx,
  ...others
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const [item, setItem] = useState<NavItemType>();
  let customLocation = location.pathname;

  const iconSX = {
    marginRight: theme.spacing(0.75),
    marginTop: `-${theme.spacing(0.25)}`,
    width: '1rem',
    height: '1rem',
    color: theme.palette.secondary.main
  };

  useEffect(() => {
    if (isSpecialBreadcrumb(customLocation)) {
      getCustomItem(customLocation);
    } else {
      navigation?.items?.map((menu: NavItemType, index: number) => {
        if (menu.type && menu.type === 'group') {
          getCollapse(menu as { children: NavItemType[]; type?: string });
        }
        return false;
      });
    }
  }, [customLocation]);

  // set active item state
  const getCollapse = (menu: NavItemType) => {
    if (menu.children) {
      menu.children.filter((collapse: NavItemType) => {
        if (collapse.type && collapse.type === 'collapse') {
          getCollapse(collapse as { children: NavItemType[]; type?: string });
          if (collapse.url === customLocation) {
            setItem(collapse);
          }
        } else if (collapse.type && collapse.type === 'item') {
          if (customLocation === collapse.url) {
            setItem(collapse);
          }
        }
        return false;
      });
    }
  };

  // This function is used to add  custom title for the special case (create / update / view)
  const getCustomItem = (customLocation: string): void => {
    let customItem: NavItemType = {
      type: 'item',
      url: customLocation
    };
    if (customLocation.includes('create')) {
      customItem.title = `new_${formatTitle(customLocation, -2)}`;
    } else if (customLocation.includes('/update/')) {
      customItem.title = `edit_${formatTitle(customLocation, -3)}`;
    } else if (customLocation.includes('/customers/cart/')) {
      customItem.title = 'cart';
    } else if (customLocation.includes('/view')) {
      customItem.title = `view_${formatTitle(customLocation, -3)}`;
    } else if (customLocation.includes('profile/update')) {
      //special case for profile update
      customItem.title = `edit_${customLocation.split('/').slice(-2)[0]}`;
    }
    setItem(customItem);
  };
  // This function is used to format the title of the special case (create / update / view)
  const formatTitle = (customLocation: string, off: number): string => {
    let title = customLocation.split('/').slice(off)[0]?.replaceAll('-', '_');
    const lastChar = title?.substring(title?.length - 1);
    if (lastChar === 's') {
      title = title.slice(0, -1);
    }
    return title;
  };

  // This function is used to get the current location of the user that is used to generate the breadcrumbs
  const getCurrentLocation = (customLocation: string): string[] => {
    let temp = customLocation.split('/').filter((item) => item != '' && item != 'apps' && item != 'list');
    if (customLocation.includes('view') || customLocation.includes('/update/') || customLocation.includes('/customers/cart/')) {
      let loc = handlePageName(temp, -2);
      return loc;
    } else {
      let loc = handlePageName(temp, -1);
      return loc;
    }
  };

  // This function is used to check if the current location is a special case (create / update / view)
  const isSpecialBreadcrumb = (location: string): boolean => {
    const specialKeywords = ['create', '/update/', '/view', 'profile/update', '/customers/cart/'];
    return specialKeywords.some((keyword: string) => location.includes(keyword));
  };

  // This function is used to handle the plural of the last word in the path
  // ex: seller -> sellers
  const handlePageName = (path: string[], off: number): string[] => {
    let loc = path.slice(0, off);
    const lastWord: string = loc.pop() ?? '';
    const lastChar = lastWord?.substring(lastWord?.length - 1);
    if (lastWord.length > 0) {
      if (lastChar !== 's') {
        if (
          [
            'dashboard',
            'user-managment',
            'product-library',
            'seller-bulk-update',
            'catalogue',
            'marketing',
            'seo',
            'meta',
            'on-page',
            'content',
            'medex-slider',
            'seller-education-center',
            'profile',
            'finance'
          ].includes(lastWord)
        )
          loc.push(lastWord!);
        else if (lastWord === 'category') loc.push('categories');
        else loc.push(`${lastWord?.replaceAll('-', '_')}s`);
      } else {
        loc.push(lastWord!);
      }
    }

    return loc;
  };

  // item separator
  const SeparatorIcon = separator!;
  const separatorIcon = separator ? <SeparatorIcon style={{ fontSize: '0.75rem', marginTop: 2 }} /> : '/';

  let itemContent;
  let breadcrumbContent: ReactElement = <Typography />;
  let itemTitle: NavItemType['title'] = '';
  let CollapseIcon: any;
  let ItemIcon;

  const currentLocation = getCurrentLocation(customLocation);

  const mainContent = currentLocation?.map((content, idx) => (
    <Typography
      component={Link}
      to={document.location.pathname}
      variant={idx == currentLocation.length - 1 && !item ? 'subtitle1' : 'h6'}
      sx={{ textDecoration: 'none' }}
      color={idx == currentLocation.length - 1 && !item ? 'textPrimary' : 'textSecondary'}
    >
      {icons && <CollapseIcon style={iconSX} />}
      {t(content ?? '')}
    </Typography>
  ));

  // items
  itemTitle = t(item?.title ?? '');

  ItemIcon = item?.icon ? item?.icon : ApartmentOutlined;

  if (item)
    itemContent = //ex: create_seller -> Create
      (
        <Typography variant="subtitle1" color="textPrimary">
          {icons && <ItemIcon style={iconSX} />}
          {itemTitle}
        </Typography>
      );

  // main
  if (item?.breadcrumbs !== false) {
    breadcrumbContent = (
      <MainCard
        border={card}
        sx={card === false ? { mb: 3, bgcolor: 'transparent', ...sx } : { mb: 3, ...sx }}
        {...others}
        content={card}
        shadow="none"
      >
        <Grid
          container
          direction={rightAlign ? 'row' : 'column'}
          justifyContent={rightAlign ? 'space-between' : 'flex-start'}
          alignItems={rightAlign ? 'center' : 'flex-start'}
          spacing={1}
        >
          {title && !titleBottom && (
            <Grid item>
              <Typography variant="h2">{t(item?.title ?? '')} </Typography>
            </Grid>
          )}
          <Grid item>
            <MuiBreadcrumbs aria-label="breadcrumb" maxItems={maxItems || 8} separator={separatorIcon}>
              <Typography component={Link} to="/" color="textSecondary" variant="h6" sx={{ textDecoration: 'none' }}>
                {icons && <HomeOutlined style={iconSX} />}
                {icon && !icons && <HomeFilled style={{ ...iconSX, marginRight: 0 }} />}
                {(!icon || icons) && t('home')}
              </Typography>
              {mainContent}
              {item && itemContent}
            </MuiBreadcrumbs>
          </Grid>
          {title && titleBottom && !item?.hideTitle && (
            <Grid item sx={{ mt: card === false ? 0.25 : 1 }}>
              <Typography variant="h2">{itemTitle}</Typography>
            </Grid>
          )}
        </Grid>
        {card === false && divider !== false && <Divider sx={{ mt: 2 }} />}
      </MainCard>
    );
  }

  return breadcrumbContent;
};

export default Breadcrumbs;
