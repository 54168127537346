const ProUser = ({ color = '#2D9B15' }: any) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_8670_244496)">
        <path
          d="M12 10.5781C13.0609 10.5781 14.0783 10.1567 14.8284 9.40655C15.5786 8.65641 16 7.63899 16 6.57812C16 5.51726 15.5786 4.49984 14.8284 3.7497C14.0783 2.99955 13.0609 2.57813 12 2.57812C10.9391 2.57813 9.92172 2.99955 9.17157 3.7497C8.42143 4.49984 8 5.51726 8 6.57812C8 7.63899 8.42143 8.65641 9.17157 9.40655C9.92172 10.1567 10.9391 10.5781 12 10.5781Z"
          stroke={color}
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5 21.5796V20.5796C4.99976 19.3985 5.29833 18.2367 5.86794 17.2021C6.43754 16.1675 7.25965 15.2938 8.25773 14.6624C9.25582 14.031 10.3974 13.6624 11.5763 13.591C12.7551 13.5195 13.9329 13.7474 15 14.2536M21 23.5796L22 17.5796L18.5 19.3796L17 17.5796L15.5 19.3796L12 17.5796L13 23.5796H21Z"
          stroke={color}
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8670_244496">
          <rect width="24" height="24" fill="white" transform="translate(0 0.578125)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ProUser;
