const SystemNotificationIcon = ({ fillColor = '#323232' }: any) => {
  return (
    <svg width="30" height="26" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.88376 2.60922C7.59171 1.84274 8.38809 1.4375 9.18747 1.4375C9.98685 1.4375 10.7832 1.84274 11.4912 2.60922C11.5775 2.70279 11.6946 2.75 11.8125 2.75C11.9189 2.75 12.0252 2.71155 12.1094 2.634C12.2867 2.46994 12.2978 2.1933 12.1338 2.01578C11.2562 1.06494 10.2372 0.5625 9.18747 0.5625C8.13773 0.5625 7.11875 1.06494 6.24118 2.01578C6.07712 2.1933 6.08823 2.46994 6.26554 2.634C6.44327 2.79742 6.72055 2.78674 6.88376 2.60922Z"
        fill={fillColor}
      />
      <path
        d="M10.9375 3.625C11.0439 3.625 11.1502 3.58655 11.2344 3.509C11.4117 3.34494 11.4228 3.0683 11.2588 2.89078C10.6452 2.2262 9.92917 1.875 9.18747 1.875C8.44577 1.875 7.72971 2.2262 7.11618 2.89078C6.95212 3.0683 6.96323 3.34494 7.14054 3.509C7.31784 3.67264 7.59513 3.66217 7.75876 3.48422C8.64572 2.52377 9.72922 2.52377 10.6162 3.48422C10.7025 3.57779 10.8196 3.625 10.9375 3.625Z"
        fill={fillColor}
      />
      <path
        d="M7.99118 3.7628C7.82712 3.94032 7.83823 4.21696 8.01554 4.38103C8.19242 4.54381 8.46927 4.53419 8.63376 4.35625C8.75894 4.22081 8.95633 4.05952 9.18747 4.05952C9.41861 4.05952 9.616 4.22081 9.74118 4.35625C9.82749 4.44981 9.94455 4.49702 10.0625 4.49702C10.1689 4.49702 10.2752 4.45857 10.3594 4.38103C10.5367 4.21696 10.5478 3.94032 10.3838 3.7628C9.68564 3.00615 8.6893 3.00615 7.99118 3.7628Z"
        fill={fillColor}
      />
      <path
        d="M12.9532 10.7083C12.4991 10.2319 12.25 9.61008 12.25 8.94998V8C12.25 6.31131 10.876 4.9375 9.1875 4.9375C8.14961 4.9375 7.23207 5.45778 6.67791 6.25H4.55615L3.80933 5.50317C3.72729 5.42114 3.61621 5.375 3.5 5.375H2.50329C2.35146 5.11473 2.07233 4.9375 1.75 4.9375C1.26764 4.9375 0.875 5.32993 0.875 5.8125C0.875 6.29507 1.26764 6.6875 1.75 6.6875C2.07233 6.6875 2.35146 6.51027 2.50329 6.25H3.31885L4.06567 6.99683C4.14771 7.07886 4.25879 7.125 4.375 7.125H6.25397C6.21162 7.26663 6.18148 7.41312 6.15995 7.5625H2.50329C2.35146 7.30223 2.07233 7.125 1.75 7.125C1.26764 7.125 0.875 7.51743 0.875 8C0.875 8.48257 1.26764 8.875 1.75 8.875C2.07233 8.875 2.35146 8.69777 2.50329 8.4375H6.125V8.875H4.375C4.25879 8.875 4.14771 8.92114 4.06567 9.00317L3.31885 9.75H2.50329C2.35146 9.48973 2.07233 9.3125 1.75 9.3125C1.26764 9.3125 0.875 9.70493 0.875 10.1875C0.875 10.6701 1.26764 11.0625 1.75 11.0625C2.07233 11.0625 2.35146 10.8853 2.50329 10.625H3.5C3.61621 10.625 3.72729 10.5789 3.80933 10.4968L4.55615 9.75H5.9952C5.87207 10.1237 5.66438 10.4669 5.37817 10.7532C5.25299 10.8784 5.21539 11.0666 5.28333 11.23C5.35126 11.3934 5.51062 11.5 5.6875 11.5H7.49964C7.69526 12.2526 8.37432 12.8125 9.1875 12.8125C10.0007 12.8125 10.6797 12.2526 10.8754 11.5H12.6875C12.6909 11.5002 12.6943 11.5 12.696 11.5C12.9379 11.5 13.1335 11.3041 13.1335 11.0625C13.1335 10.9168 13.0626 10.7878 12.9532 10.7083ZM9.1875 11.9375C8.86517 11.9375 8.58604 11.7603 8.43421 11.5H9.94079C9.78896 11.7603 9.50983 11.9375 9.1875 11.9375ZM6.56421 10.625C6.84875 10.1191 7 9.5462 7 8.94998V8C7 6.79388 7.98138 5.8125 9.1875 5.8125C10.3936 5.8125 11.375 6.79388 11.375 8V8.94998C11.375 9.5462 11.5262 10.1191 11.8108 10.625H6.56421Z"
        fill={fillColor}
      />
    </svg>
  );
};
export default SystemNotificationIcon;
