import axios from 'axios';
import axiosServices, { responseError, responseSuccess } from 'utils/axios';

export function cacheClear(token: string, i18n: string) {
  return axios
    .get(`${process.env.REACT_APP_WEBSITE_URL}api/cache/clear`, {
      headers: { Authorization: `Bearer ${token}`, 'Accept-Language': i18n }
    })
    .then((res) => {
      if (res.data.success) responseSuccess('success.cache_clear', 'common');
      else responseError('errors.cache_clear', 'common');
    });
}

export function getCategoryTree(i18n: string, parent_id?: string, child_id?: string) {
  return axiosServices
    .get(`/categories/tree?${parent_id ? 'parent_id=' + parent_id : ''}${child_id ? `&checked=true&child_id=${child_id}` : ''}`, {
      headers: { 'Accept-Language': i18n }
    })
    .then(
      (res) => {
        return res.data.items;
      },
      (err: any) => {
        responseError();
        return err;
      }
    );
}

export function getCategoryTreeChecked(i18n: string, item_id: string, module_key: string, parent_id?: string) {
  return axiosServices
    .get(`/categories/tree?checked=true${item_id ? `&${module_key}=` + item_id : ''}${parent_id ? '&parent_id=' + parent_id : ''}`, {
      headers: { 'Accept-Language': i18n }
    })
    .then(
      (res) => {
        return res.data.items;
      },
      (err: any) => {
        responseError();
        return err;
      }
    );
}
