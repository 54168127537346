const Shipment = ({ color = '#2D9B15' }: any) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_8670_244478)">
        <path
          d="M18.3516 15.6304C18.3516 14.2764 17.2501 13.175 15.8962 13.175C14.5424 13.175 13.4408 14.2766 13.4408 15.6304C13.4408 16.9842 14.5424 18.0858 15.8962 18.0858C17.25 18.0858 18.3516 16.9844 18.3516 15.6304ZM17.1557 15.6306C17.1557 16.3252 16.5908 16.8901 15.8962 16.8901C15.2017 16.8901 14.6367 16.3252 14.6367 15.6306C14.6367 14.9361 15.2016 14.3709 15.8962 14.3709C16.5908 14.3709 17.1557 14.9361 17.1557 15.6306Z"
          fill={color}
          stroke={color}
          stroke-width="0.1"
        />
        <path
          d="M7.16387 15.6304C7.16387 14.2764 6.0625 13.175 4.70848 13.175C3.35445 13.175 2.25305 14.2766 2.25309 15.6304C2.25309 16.9842 3.35465 18.0858 4.70848 18.0858C6.06227 18.0858 7.16387 16.9844 7.16387 15.6304ZM5.96797 15.6306C5.96797 16.3252 5.40304 16.8901 4.70848 16.8901C4.01392 16.8901 3.44875 16.3252 3.44875 15.6306C3.44875 14.9361 4.01391 14.3709 4.70848 14.3709C5.40306 14.3709 5.96797 14.9361 5.96797 15.6306Z"
          fill={color}
          stroke={color}
          stroke-width="0.1"
        />
        <path
          d="M17.5378 6.28438H12.253V7.48027H17.1428H17.1733L17.1872 7.50737L18.7485 10.5372L18.7541 10.548V10.5601V14.9817V15.0317H18.7041H17.8254V16.2276H19.3521C19.6823 16.2276 19.95 15.9599 19.95 15.6296V10.4029C19.95 10.3076 19.9273 10.2137 19.8836 10.129L19.8836 10.129L18.0694 6.60846C17.9668 6.40935 17.7616 6.28438 17.5378 6.28438Z"
          fill={color}
          stroke={color}
          stroke-width="0.1"
        />
        <path d="M13.9241 16.2303V15.0344H6.63752V16.2303H13.9241Z" fill={color} stroke={color} stroke-width="0.1" />
        <path
          d="M11.5549 4.26152H11.6049V4.31152V15.579H12.8008V3.66355C12.8008 3.33332 12.5332 3.06563 12.2029 3.06563H0.647823C0.317588 3.06563 0.0498924 3.33332 0.0498924 3.66359V15.629C0.0498924 15.9593 0.317549 16.227 0.647823 16.227H2.80083V15.0311H1.29575H1.24575V14.9811V4.31152V4.26152H1.29575H11.5549Z"
          fill={color}
          stroke={color}
          stroke-width="0.1"
        />
        <path d="M19.3023 11.0193V9.82344H12.2533V11.0193H19.3023Z" fill={color} stroke={color} stroke-width="0.1" />
        <path
          d="M6.14575 11.1719H5.052C4.10435 11.1719 3.33325 10.4008 3.33325 9.45319C3.33325 8.50553 4.10435 7.73444 5.052 7.73444H6.77075V6.71881C6.77075 6.60905 6.82817 6.50748 6.92231 6.45084C7.01606 6.39459 7.13286 6.39108 7.22974 6.44303L9.72974 7.77116C9.83208 7.82506 9.89575 7.93131 9.89575 8.04694C9.89575 8.16256 9.83169 8.26881 9.72974 8.32272L7.22974 9.65084C7.18403 9.67545 7.13364 9.68756 7.08325 9.68756C7.02739 9.68756 6.97192 9.67272 6.92231 9.64303C6.82817 9.58639 6.77075 9.48444 6.77075 9.37506V8.35944H5.052C4.44888 8.35944 3.95825 8.85006 3.95825 9.45319C3.95825 10.0563 4.44888 10.5469 5.052 10.5469H6.14575C6.31841 10.5469 6.45825 10.6868 6.45825 10.8594C6.45825 11.0321 6.31841 11.1719 6.14575 11.1719Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_8670_244478">
          <rect width="20" height="20" fill="white" transform="translate(0 0.578125)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Shipment;
