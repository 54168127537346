const OrderReturn = ({ color = '#2D9B15' }: any) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_8670_244472)">
        <path
          d="M18.596 9.28136C18.2147 8.90152 17.7712 8.58972 17.2846 8.35957V4.46369C17.2847 4.43341 17.2799 4.4033 17.2704 4.37455C17.2439 4.29095 17.1838 4.22216 17.1046 4.18466L8.76724 0.463545C8.68857 0.428818 8.59896 0.428818 8.52029 0.463545L0.184773 4.18732C0.104266 4.22634 0.043563 4.29705 0.0171569 4.38255C-0.0203039 4.46458 0.0171569 13.2717 0.00465691 13.36C0.00473503 13.4789 0.0745004 13.5867 0.182977 13.6354L8.51849 17.3601C8.5972 17.3946 8.68673 17.3946 8.76544 17.3601L11.9329 15.9498C16.4671 20.2174 22.8619 13.8236 18.596 9.28136ZM4.90882 6.85114V9.82874L3.58761 9.23859V6.26008L4.90882 6.85114ZM12.8155 2.93389L5.22264 6.32782C4.93827 6.20032 4.31241 5.9204 4.03249 5.79294L11.6263 2.3999L12.8155 2.93389ZM8.64779 7.85586L5.96436 6.65946L13.5582 3.26642L16.2389 4.46372L8.64779 7.85586ZM8.64779 1.07155L10.8881 2.07182L3.29074 5.46485L1.12082 4.49579L1.0495 4.46369L8.64779 1.07155ZM0.610006 13.1638V4.93083L2.9832 5.98993V9.43558C2.98293 9.5548 3.05277 9.66304 3.16148 9.71195L5.088 10.5723C5.241 10.6402 5.4201 10.5712 5.48803 10.4183C5.50514 10.3798 5.51405 10.3381 5.51413 10.2959V7.12395L8.34107 8.38273V16.6175L0.610006 13.1638ZM8.94732 8.38273L16.6793 4.93083V8.11793C14.499 7.43949 12.1454 8.41738 11.0878 10.4412L10.7428 9.60136C10.6798 9.44675 10.5034 9.37253 10.3488 9.43554C10.1941 9.49855 10.1199 9.67499 10.1829 9.8296L10.8195 11.3835C10.8832 11.5379 11.0598 11.6117 11.2144 11.5484L12.7674 10.911C12.9196 10.8426 12.9876 10.6637 12.9192 10.5115C12.854 10.3663 12.6874 10.2966 12.5382 10.352L11.6191 10.7282C12.5723 8.8946 14.7378 8.05262 16.6793 8.7607V13.1638L8.94728 16.6175L8.94732 8.38273ZM18.169 15.5147C16.6266 17.0442 14.1617 17.1137 12.5356 15.6734L17.1054 13.6319C17.214 13.5828 17.2838 13.4747 17.2838 13.3555V9.04066C19.2603 10.1705 19.9468 12.6887 18.817 14.6652C18.639 14.9766 18.4212 15.2633 18.169 15.5183L18.169 15.5147Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_8670_244472">
          <rect width="20" height="20" fill="white" transform="translate(0 0.578125)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default OrderReturn;
