const User = ({ color = '#2D9B15' }: any) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0001 8.90885C11.841 8.90885 13.3334 7.41647 13.3334 5.57552C13.3334 3.73457 11.841 2.24219 10.0001 2.24219C8.15913 2.24219 6.66675 3.73457 6.66675 5.57552C6.66675 7.41647 8.15913 8.90885 10.0001 8.90885Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5 18.9063C17.5 14.7642 14.1421 11.4062 10 11.4062C5.85792 11.4062 2.5 14.7642 2.5 18.9063"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default User;
