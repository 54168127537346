// assets
import {
  TagOutlined,
  TagsOutlined,
  NotificationOutlined,
  FormOutlined,
  BellOutlined,
  UsergroupAddOutlined,
  AppstoreOutlined
} from '@ant-design/icons';
import CampaginsIcon from 'assets/images/side-menu/CampaginsIcon';

import { SellerCampaignIcon } from 'assets/images/icons/sellerCampaign';
// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  TagOutlined,
  TagsOutlined,
  NotificationOutlined,
  FormOutlined,
  BellOutlined,
  UsergroupAddOutlined,
  AppstoreOutlined,
  SellerCampaignIcon
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const marketing: NavItemType = {
  id: 'marketing',
  title: 'marketing',
  type: 'group',
  permissionKey: [
    'VIEW_COUPONS_LIST',
    'VIEW_COUPONS_ARCHIVE',
    'VIEW_ADVERTISEMENT_LIST',
    'LIST_SEO_META',
    'LIST_SEO_META_ARCHIVE',
    'LIST_SEO_ON_PAGE',
    'LIST_SEO_ON_PAGE_ARCHIVE',
    'LIST_AUDIENCES',
    'LIST_AUDIENCE_NOTIFICATIONS',
    'ALL'
  ],
  children: [
    {
      id: 'bulk_discount',
      title: 'bulk-discount',
      type: 'item',
      permissionKey: ['ALL'],
      url: '/apps/marketing/bulk-discount/list',
      icon: icons.TagOutlined
    },
    {
      id: 'coupons',
      title: 'coupons',
      type: 'item',
      permissionKey: ['VIEW_COUPONS_LIST', 'VIEW_COUPONS_ARCHIVE'],
      url: '/apps/marketing/coupons/list',
      icon: icons.TagsOutlined
    },
    {
      id: 'seller_campaign',
      title: 'seller_campaign',
      type: 'item',
      permissionKey: ['VIEW_COUPONS_LIST', 'VIEW_COUPONS_ARCHIVE'],
      url: '/apps/marketing/seller-campaign/list',
      icon: (props) => {
        return <SellerCampaignIcon sx={{ fill: props?.style?.color === 'selected' ? '#222A95' : '#323232', width: 16, height: 16 }} />;
      }
    },
    {
      id: 'advertisement',
      title: 'advertisement',
      type: 'item',
      permissionKey: ['VIEW_ADVERTISEMENT_LIST'],
      url: '/apps/marketing/advertisement',
      icon: icons.NotificationOutlined
    },
    {
      id: 'mega-menu',
      title: 'mega-menu',
      type: 'item',
      permissionKey: ['ADMINS'],
      url: '/apps/marketing/mega-menu',
      icon: icons.AppstoreOutlined
    },
    {
      id: 'seo',
      title: 'seo',
      type: 'collapse',
      permissionKey: ['LIST_SEO_META', 'LIST_SEO_META_ARCHIVE', 'LIST_SEO_ON_PAGE', 'LIST_SEO_ON_PAGE_ARCHIVE'],
      icon: icons.FormOutlined,
      children: [
        {
          id: 'meta',
          title: 'meta',
          type: 'item',
          url: '/apps/marketing/seo/meta/list',
          permissionKey: ['LIST_SEO_META', 'LIST_SEO_META_ARCHIVE'],
          icon: icons.FormOutlined
        },
        {
          id: 'on-page',
          title: 'on-page',
          type: 'item',
          url: '/apps/marketing/seo/on-page/list',
          permissionKey: ['LIST_SEO_ON_PAGE', 'LIST_SEO_ON_PAGE_ARCHIVE'],
          icon: icons.FormOutlined
        }
      ]
    },
    {
      id: 'notifications',
      title: 'notifications',
      type: 'item',
      permissionKey: ['LIST_AUDIENCE_NOTIFICATIONS'],
      url: '/apps/marketing/notifications/list',
      icon: icons.BellOutlined
    },
    {
      id: 'audience',
      title: 'audience',
      type: 'item',
      permissionKey: ['LIST_AUDIENCES'],
      url: '/apps/marketing/audience/list',
      icon: icons.UsergroupAddOutlined
    },
    {
      id: 'campaigns',
      title: 'campaigns',
      type: 'item',
      permissionKey: ['SELLER'],
      url: '/apps/marketing/campaigns/list',
      icon: (props) => {
        return <CampaginsIcon fillColor={props?.style?.color === 'selected' ? '#222A95' : '#323232'} />;
      }
    }
  ]
};

export default marketing;
