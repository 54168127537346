import { createContext, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// project import
import config from 'config';

// types
import { CustomizationProps, I18n, MenuOrientation, ThemeDirection } from 'types/config';

// initial state
const initialState: CustomizationProps = {
  ...config,
  onChangeLocalization: (lang: I18n) => { },
  onChangeDirection: (direction: ThemeDirection) => { },
  onChangeMiniDrawer: (miniDrawer: boolean) => { },
  onChangeMenuOrientation: (menuOrientation: MenuOrientation) => { }
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ConfigContext = createContext(initialState);

type ConfigProviderProps = {
  children: ReactNode;
};

function ConfigProvider({ children }: ConfigProviderProps) {
  const [config, setConfig] = useState(initialState);
  const { i18n } = useTranslation();

  useEffect(() => {
    const currentConfig = window.localStorage.getItem('config');
    if (!currentConfig) onChangeLocalization(config.i18n);
    else onChangeLocalization(JSON.parse(currentConfig)?.i18n);
  }, [])

  const onChangeLocalization = (lang: I18n) => {
    i18n.changeLanguage(lang);
    setConfig({
      ...config,
      i18n: lang
    });
    localStorage.setItem('config', JSON.stringify({
      ...config,
      i18n: lang
    }));
  };

  const onChangeDirection = (direction: ThemeDirection) => {
    setConfig({
      ...config,
      themeDirection: direction
    });
  };

  const onChangeMiniDrawer = (miniDrawer: boolean) => {
    setConfig({
      ...config,
      miniDrawer
    });
  };

  const onChangeMenuOrientation = (layout: MenuOrientation) => {
    setConfig({
      ...config,
      menuOrientation: layout
    });
  };

  return (
    <ConfigContext.Provider
      value={{
        ...config,
        onChangeLocalization,
        onChangeDirection,
        onChangeMiniDrawer,
        onChangeMenuOrientation
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export { ConfigProvider, ConfigContext };
