// third-party
import { combineReducers } from 'redux';

// project import
import profile from './profile';
import menu from './menu';
import snackbar from './snackbar';
import customer from './customer';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  profile,
  menu,
  snackbar,
  customer
});

export default reducers;
