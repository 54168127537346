import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
import { APP_DEFAULT_PATH } from 'config';
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import { getRedirectionLink } from 'utils/globals';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn && user?.id) {
      navigate(location?.state?.from ? location?.state?.from : user ? getRedirectionLink(user) ?? APP_DEFAULT_PATH : APP_DEFAULT_PATH, {
        state: {
          from: ''
        },
        replace: true
      });
    } else if (location.pathname === '/') {
      navigate('/seller/login', {
        state: {
          from: ''
        },
        replace: true
      });
    }
  }, [isLoggedIn, navigate, location]);

  return children;
};

export default GuestGuard;
