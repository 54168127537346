import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// translation files
import auth_tr from 'utils/locales/auth/tr.json';
import auth_en from 'utils/locales/auth/en.json';
import catalogue_tr from 'utils/locales/catalogue/tr.json';
import catalogue_en from 'utils/locales/catalogue/en.json';
import common_tr from 'utils/locales/common/tr.json';
import common_en from 'utils/locales/common/en.json';
import content_tr from 'utils/locales/content/tr.json';
import content_en from 'utils/locales/content/en.json';
import finance_tr from 'utils/locales/finance/tr.json';
import finance_en from 'utils/locales/finance/en.json';
import marketing_tr from 'utils/locales/marketing/tr.json';
import marketing_en from 'utils/locales/marketing/en.json';
import orders_tr from 'utils/locales/orders/tr.json';
import orders_en from 'utils/locales/orders/en.json';
import settings_tr from 'utils/locales/settings/tr.json';
import settings_en from 'utils/locales/settings/en.json';
import user_managment_tr from 'utils/locales/user-managment/tr.json';
import user_managment_en from 'utils/locales/user-managment/en.json';
import dashboard_tr from 'utils/locales/dashboard/tr.json';
import dashboard_en from 'utils/locales/dashboard/en.json';

i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: ['auth', 'common', 'catalogue', 'content', 'finance', 'marketing', 'orders', 'settings', 'user_managment', 'dashboard'],
    defaultNS: 'common',
    resources: {
      en: {
        //en name spaces
        auth: auth_en,
        common: common_en,
        catalogue: catalogue_en,
        content: content_en,
        finance: finance_en,
        marketing: marketing_en,
        orders: orders_en,
        settings: settings_en,
        user_managment: user_managment_en,
        dashboard: dashboard_en
      },
      tr: {
        //tr name spaces
        auth: auth_tr,
        common: common_tr,
        catalogue: catalogue_tr,
        content: content_tr,
        finance: finance_tr,
        marketing: marketing_tr,
        orders: orders_tr,
        settings: settings_tr,
        user_managment: user_managment_tr,
        dashboard: dashboard_tr
      }
    },
    fallbackLng: 'tr',
    lng: 'tr',
    debug: true,
    initImmediate: false,
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export { i18n as i18nInit };

export default i18n;
