const VanIcon = ({ fillColor = '#323232' }: any) => {
  return (
    <svg
      className="svg"
      style={{ marginRight: '5px' }}
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="svg"
        fill={fillColor}
        d="M15.5455 3.27273H13.0909V0H1.63636C0.736364 0 0 0.736364 0 1.63636V10.6364H1.63636C1.63636 11.9945 2.73273 13.0909 4.09091 13.0909C5.44909 13.0909 6.54545 11.9945 6.54545 10.6364H11.4545C11.4545 11.9945 12.5509 13.0909 13.9091 13.0909C15.2673 13.0909 16.3636 11.9945 16.3636 10.6364H18V6.54545L15.5455 3.27273ZM15.1364 4.5L16.74 6.54545H13.0909V4.5H15.1364ZM4.09091 11.4545C3.64091 11.4545 3.27273 11.0864 3.27273 10.6364C3.27273 10.1864 3.64091 9.81818 4.09091 9.81818C4.54091 9.81818 4.90909 10.1864 4.90909 10.6364C4.90909 11.0864 4.54091 11.4545 4.09091 11.4545ZM5.90727 9C5.45727 8.50091 4.81909 8.18182 4.09091 8.18182C3.36273 8.18182 2.72455 8.50091 2.27455 9H1.63636V1.63636H11.4545V9H5.90727ZM13.9091 11.4545C13.4591 11.4545 13.0909 11.0864 13.0909 10.6364C13.0909 10.1864 13.4591 9.81818 13.9091 9.81818C14.3591 9.81818 14.7273 10.1864 14.7273 10.6364C14.7273 11.0864 14.3591 11.4545 13.9091 11.4545Z"
      />
    </svg>
  );
};
export default VanIcon;
