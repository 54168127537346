import { WarningOutlined } from '@ant-design/icons';
import { Dialog, DialogContent, Typography, Button, Stack } from '@mui/material';
import Avatar from 'components/@extended/Avatar';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const CompleteInfoDialog = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['user_managment', 'common']);
  const { user, showInfoComplete, skipCompleteInfo } = useAuth();

  return (
    <Dialog open={showInfoComplete ?? false} maxWidth="xs">
      <DialogContent sx={{ mt: 2, my: 1 }}>
        <Stack alignItems="center" spacing={1.5}>
          <Avatar color={'warning'} sx={{ width: 72, height: 72, fontSize: '1.75rem' }}>
            <WarningOutlined />
          </Avatar>
          <Stack spacing={2}>
            <Typography variant="h3" align="center" fontWeight={'700'}>
              {t('seller.headers.complete_popup')}
            </Typography>
            <Typography align="center" color="secondary" variant="h5" fontWeight={'400'}>
              {t('seller.labels.complete_popup')}
            </Typography>
            <Stack direction="row" spacing={2} sx={{ width: 1 }}>
              <Button
                fullWidth
                onClick={() => {
                  skipCompleteInfo();
                }}
                color="secondary"
                variant="outlined"
              >
                {t('buttons.cancel', { ns: 'common' })}
              </Button>
              <Button
                fullWidth
                color={'primary'}
                variant="contained"
                onClick={() => {
                  skipCompleteInfo();
                  navigate('/apps/user-managment/seller/update/' + user?.id);
                }}
                autoFocus
              >
                {t('buttons.continue', { ns: 'common' })}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
