const DeliveryReturn = ({ color = '#2D9B15' }: any) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_8670_244471)">
        <path
          d="M3.20964 13.7529L9.37735 15.577C9.44202 15.5864 9.50772 15.5864 9.57239 15.577L15.7403 13.7529C15.8114 13.7318 15.8738 13.6884 15.9181 13.6289C15.9625 13.5695 15.9865 13.4974 15.9865 13.4232V7.22633C15.9846 7.15538 15.9616 7.0866 15.9204 7.02878C15.8792 6.97096 15.8218 6.92672 15.7553 6.90172L9.58748 4.76132C9.51456 4.73581 9.43515 4.73581 9.36223 4.76132L3.19454 6.9017C3.13026 6.92548 3.07433 6.96748 3.03358 7.02258C2.99283 7.07768 2.96904 7.14347 2.96513 7.21188C2.94766 7.22284 2.97411 13.4102 2.96339 13.4232C2.9634 13.4974 2.98738 13.5695 3.03176 13.6289C3.07614 13.6884 3.13854 13.7318 3.20964 13.7529ZM15.299 13.1664L9.81861 14.7871V9.44659L15.299 7.69696V13.1664ZM9.47486 5.44983L14.5555 7.21275L9.47486 8.83482L4.39439 7.21274L9.47486 5.44983ZM3.65091 7.69696L9.13111 9.44659V14.7871L3.65091 13.1664V7.69696Z"
          fill={color}
        />
        <path
          d="M7.66631 9.34345L5.32082 8.60996C5.26941 8.59346 5.21481 8.58949 5.16156 8.59836C5.1083 8.60724 5.05794 8.62871 5.01467 8.66099C4.97121 8.69291 4.93588 8.73462 4.91154 8.78273C4.88719 8.83085 4.8745 8.88401 4.87451 8.93793V10.2119C4.87448 10.2853 4.89801 10.3568 4.94163 10.4159C4.98526 10.475 5.04668 10.5186 5.11688 10.5402L7.46237 11.2643C7.51377 11.2796 7.56803 11.2828 7.62088 11.2736C7.67373 11.2644 7.72372 11.2431 7.76689 11.2112C7.81006 11.1794 7.84524 11.1379 7.86964 11.0902C7.89404 11.0424 7.90701 10.9896 7.9075 10.9359V9.67142C7.90754 9.59818 7.88415 9.52684 7.84076 9.46783C7.79736 9.40883 7.73624 9.36524 7.66631 9.34345ZM7.22 10.47L5.56201 9.95811V9.40555L7.22 9.9242V10.47Z"
          fill={color}
        />
        <path
          d="M12.8988 13.5433C12.9346 13.5433 14.6805 12.9726 14.6805 12.9726C14.7654 12.9427 14.8351 12.8806 14.8748 12.7998C14.9144 12.719 14.9208 12.6259 14.8925 12.5405C14.8643 12.455 14.8036 12.3841 14.7236 12.3429C14.6436 12.3016 14.5506 12.2934 14.4646 12.32L12.7909 12.8732C12.7145 12.9 12.65 12.9527 12.6086 13.0222C12.5672 13.0917 12.5516 13.1736 12.5644 13.2534C12.5773 13.3333 12.6179 13.4061 12.679 13.4591C12.7401 13.5121 12.8179 13.5419 12.8988 13.5433Z"
          fill={color}
        />
        <path
          d="M13.2628 12.3935C13.2525 12.4342 14.6488 11.9462 14.6805 11.9434C14.7233 11.9292 14.763 11.9068 14.7971 11.8773C14.8313 11.8478 14.8593 11.8119 14.8796 11.7716C14.8999 11.7313 14.9121 11.6874 14.9154 11.6424C14.9187 11.5974 14.9131 11.5522 14.899 11.5093C14.8848 11.4665 14.8624 11.4268 14.8329 11.3926C14.8034 11.3585 14.7675 11.3304 14.7272 11.3101C14.6869 11.2898 14.643 11.2777 14.598 11.2744C14.553 11.271 14.5078 11.2766 14.4649 11.2908L13.1551 11.7235C13.0787 11.7502 13.0141 11.8029 12.9727 11.8724C12.9313 11.9419 12.9156 12.0237 12.9284 12.1036C12.9413 12.1835 12.9818 12.2563 13.043 12.3093C13.1041 12.3623 13.1819 12.3921 13.2628 12.3935Z"
          fill={color}
        />
        <path
          d="M17.5822 15.5891H16.2651C16.1751 15.5908 16.0893 15.6278 16.0262 15.692C15.9632 15.7563 15.9278 15.8428 15.9278 15.9328C15.9278 16.0229 15.9632 16.1094 16.0262 16.1736C16.0893 16.2379 16.1751 16.2749 16.2651 16.2766H16.918C11.858 22.7505 1.02218 18.9286 1.06248 10.5801C1.06189 9.03716 1.4608 7.52031 2.22037 6.17725C2.24263 6.13799 2.25695 6.09473 2.26249 6.04994C2.26804 6.00516 2.2647 5.95972 2.25269 5.91621C2.24067 5.87271 2.2202 5.83201 2.19245 5.79642C2.16471 5.76082 2.13022 5.73105 2.09096 5.70879C2.0517 5.68652 2.00845 5.67221 1.96366 5.66667C1.91887 5.66112 1.87343 5.66445 1.82993 5.67647C1.78643 5.68849 1.74572 5.70895 1.71013 5.7367C1.67454 5.76445 1.64476 5.79894 1.6225 5.8382C0.804116 7.28461 0.374293 8.91832 0.374898 10.5802C0.328595 19.409 11.6126 23.5864 17.2385 16.9771C17.2095 17.2177 17.3064 17.4665 17.5822 17.4706C17.6274 17.4707 17.6721 17.4618 17.7138 17.4445C17.7555 17.4272 17.7934 17.4019 17.8253 17.37C17.8572 17.3381 17.8825 17.3002 17.8998 17.2584C17.9171 17.2167 17.926 17.172 17.926 17.1269C17.9278 17.1003 17.9374 15.8051 17.9052 15.8301C17.8823 15.7619 17.8391 15.7022 17.7814 15.6592C17.7236 15.6161 17.6542 15.5916 17.5822 15.5891Z"
          fill={color}
        />
        <path
          d="M10.0001 0.954718C7.65892 0.911213 5.38895 1.76036 3.65112 3.32973V2.95882C3.64972 2.86858 3.61289 2.7825 3.54857 2.71918C3.48426 2.65585 3.39762 2.62036 3.30736 2.62037C3.21711 2.62037 3.13047 2.65586 3.06616 2.71919C3.00185 2.78252 2.96502 2.8686 2.96362 2.95884V4.15288C2.96652 4.18181 2.97229 4.21038 2.98085 4.23817C2.99964 4.31096 3.04172 4.37561 3.10067 4.42227C3.15962 4.46893 3.23221 4.49504 3.30737 4.49661L4.62462 4.49663C4.71463 4.49487 4.80036 4.45788 4.86339 4.39361C4.92642 4.32933 4.96173 4.2429 4.96173 4.15287C4.96172 4.06285 4.92641 3.97641 4.86338 3.91214C4.80034 3.84787 4.71461 3.81088 4.6246 3.80913H4.14495C11.0173 -2.09659 21.3704 4.84876 18.4442 13.5158C18.4161 13.6014 18.4227 13.6947 18.4627 13.7755C18.5027 13.8563 18.5728 13.9182 18.658 13.9478C18.7432 13.9773 18.8366 13.9723 18.918 13.9336C18.9995 13.895 19.0625 13.8259 19.0935 13.7413C21.3159 7.65448 16.4984 0.840287 10.0001 0.954718Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_8670_244471">
          <rect width="20" height="20" fill="white" transform="translate(0 0.578125)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default DeliveryReturn;
