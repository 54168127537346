const Review = ({ color = '#2D9B15' }: any) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6704 8.27916L10.0001 4.89287L8.32974 8.27916L4.59208 8.82236L7.29678 11.4593L6.65816 15.1807L10.0001 13.4237L13.342 15.1807L12.7034 11.4593L15.4081 8.82236L11.6704 8.27916ZM16.9114 7.35668L17.7364 7.47657C18.0782 7.52735 18.3165 7.84376 18.2637 8.18555C18.2442 8.32227 18.1798 8.44727 18.0821 8.54298L17.4859 9.12429L14.4942 12.041L15.3419 16.9805C15.4005 17.3203 15.1719 17.6426 14.8321 17.7012C14.6973 17.7246 14.5567 17.7031 14.4356 17.6387L13.6972 17.2505L10.0001 15.3066L6.30297 17.2504L5.56452 17.6387C5.4614 17.6931 5.34509 17.7176 5.22878 17.7093C5.11246 17.701 5.0008 17.6603 4.90645 17.5918C4.8121 17.5232 4.73884 17.4296 4.69497 17.3216C4.65111 17.2136 4.63839 17.0954 4.65827 16.9805L5.50593 12.041L1.91804 8.54298C1.79923 8.42716 1.73126 8.26892 1.72906 8.10302C1.72686 7.93712 1.79062 7.77713 1.90632 7.65821C2.00202 7.56055 2.12702 7.4961 2.26374 7.47657L3.08868 7.35668L7.22272 6.75587L9.43952 2.26173C9.59382 1.95313 9.96882 1.82618 10.2774 1.97852C10.4005 2.03907 10.5001 2.13868 10.5606 2.26173L10.9292 3.00905L12.7774 6.75587L16.9114 7.35668Z"
        fill={color}
      />
    </svg>
  );
};
export default Review;
