const BulkProduct = ({ color = '#2D9B15' }: any) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2009 5.81179L10.2007 5.81194H10.2012H18.2146C18.6094 5.81194 18.9284 6.13089 18.9284 6.52567V17.1864C18.9284 17.5812 18.6094 17.9001 18.2146 17.9001H1.78606C1.39128 17.9001 1.07234 17.5812 1.07234 17.1864V3.9721C1.07234 3.57732 1.39128 3.25837 1.78606 3.25837H7.46017C7.50566 3.25876 7.54935 3.27624 7.58256 3.30733L10.2009 5.81179ZM6.8869 4.86455L6.88704 4.8644H6.88651H2.67892V4.86496H2.67836V16.2935H2.67892V16.2941H17.3218V16.2935H17.3223V7.41853H17.3218V7.41797H9.55638L6.8869 4.86455Z"
        fill={color}
        stroke={color}
        stroke-width="0.00111607"
      />
    </svg>
  );
};
export default BulkProduct;
